import { openDB } from 'idb';

export class LocalDB {
  static get collectionStoreName() {
    return 'collection';
  }

  static get podcastStoreName() {
    return 'podcast';
  }

  static get episodeStoreName() {
    return 'episode';
  }

  static get activityStoreName() {
    return 'activity';
  }

  static get tagStoreName() {
    return 'tag';
  }

  static getStoreName(contentType) {
    let storeName = null;
    switch (contentType) {
      case 'collection':
        storeName = LocalDB.collectionStoreName;
        break;
      case 'podcast':
        storeName = LocalDB.podcastStoreName;
        break;
      case 'episode':
        storeName = LocalDB.episodeStoreName;
        break;
      case 'tag':
        storeName = LocalDB.tagStoreName;
        break;
      case 'activity':
        storeName = LocalDB.activityStoreName;
        break;
      default:
        break;
    }
    if (storeName === null) { throw new Error('Unknown content type, it must be between collection, podcast, episode, or activity!'); }
    return storeName;
  }

  constructor(DBName) {
    this.DBName = DBName;
    this.version = 1;
    this.db = null;
  }

  async initDB() {
    this.db = await openDB(this.DBName, this.version, {
      upgrade(db) {
        const collectionStore = db.createObjectStore(LocalDB.collectionStoreName);
        collectionStore.createIndex('slug', 'slug', { unique: true });
        const podcastStore = db.createObjectStore(LocalDB.podcastStoreName);
        podcastStore.createIndex('slug', 'slug', { unique: true });
        const episodeStore = db.createObjectStore(LocalDB.episodeStoreName);
        episodeStore.createIndex('slug', 'slug', { unique: true });
        const activityStore = db.createObjectStore(LocalDB.activityStoreName);
        activityStore.createIndex('contentType', 'contentType');
        const tagStore = db.createObjectStore(LocalDB.tagStoreName);
        tagStore.createIndex('slug', 'slug', { unique: true });
      },
    });
    return this.db;
  }

  get collectionStore() {
    return this.db.transaction(LocalDB.collectionStoreName).objectStore(LocalDB.collectionStoreName);
  }

  get podcastStore() {
    return this.db.transaction(LocalDB.podcastStoreName).objectStore(LocalDB.podcastStoreName);
  }

  get episodeStore() {
    return this.db.transaction(LocalDB.episodeStoreName).objectStore(LocalDB.episodeStoreName);
  }

  get activityStore() {
    return this.db.transaction(LocalDB.activityStoreName).objectStore(LocalDB.activityStoreName);
  }

  get tagStore() {
    return this.db.transaction(LocalDB.tagStoreName).objectStore(LocalDB.tagStoreName);
  }

  async put(contentType, value, key) {
    if (isNaN(key)) {
      throw new Error('Key must be a numeric ID');
    }

    if (this.db === null) { await this.initDB(); }

    const storeName = LocalDB.getStoreName(contentType);
    return await this.db.put(storeName, value, Number.parseInt(key));
  }

  async getAll(contentType) {
    if (this.db === null) { await this.initDB(); }
    const storeName = LocalDB.getStoreName(contentType);

    return await this.db.getAll(storeName);
  }

  async get(contentType, key, isSlug) {
    if (this.db === null) { await this.initDB(); }
    let data = null;
    const storeName = LocalDB.getStoreName(contentType);
    data = (isSlug)
      ? await this.db.getFromIndex(storeName, 'slug', key)
      : await this.db.get(storeName, parseInt(key));
    return data;
  }

  async delete(contentType, key) {
    if (this.db === null) { await this.initDB(); }
    const storeName = LocalDB.getStoreName(contentType);
    return await this.db.delete(storeName, key);
  }
}
